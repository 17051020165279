const frenchStrings = {
    welcome_to_appendre:'Bienvenue À Appendre',
    about_appendre:'La principal site web de l\'apprentisage de Francais par les correspondants',
    login: 'Se Connecter',
    logout: 'Se Déconnecter',
    username: 'Identifiant',
    password: 'Mot de Passe',
    signup: 'Enregistrer',
    continue_as_guest: 'Continue sans se Connecter',
    profile: 'Profil',
    trending_letters: 'Lettres Populaire',
    trending_replies: 'Reponse Populaire',
    french: 'Français',
    english: 'English',
    collaborators: 'Collaborateurs',
    myLetters: 'Mes Documents',
    writeALetter: "Ecrit une Lettre",
    whatDoYouWantToSay: 'Ce Que Vous-Voulez Dire',
    title: 'Titre',
    add: 'Ajouter',
    letter: 'Lettre',
    tags: 'Étiquettes',
    by: 'Par',
    likes: 'J\'aime',
    dislikes: 'Détester',
    postedOn: 'Publié á',
    post: 'Poster',
    replies: 'Reponses',
    reply: 'Repondre',
    search: 'Rechercher',
    text: 'Texte',
    tag: 'Étiquette',
    author: 'Écrivain',
    date: 'Date',
    role: 'Rôle',
    user: 'Utilisateur',
    searchAppendre: 'Chercher Appendre',
    resultsFor: 'Resultats pour',
    with: 'avec',
    matching: 'correspondant à',
    viewProfile: 'Voir Profile',
    following: 'Suivre',
    pensIFollow: 'Pens Je Suive',
    follow: 'Suive',
    collaborate: 'Collaborer',
    myCollaborators: 'Mes Collaborateurs',
    currentPassword: 'Mot De Passe Actuel',
    newPassword: 'Nouveau Mot De Passe',
    confirmPassword: 'Vérifier Le Mot De Passe',
    submitFeedback: 'Submit les Remarques',
    feedback: 'Remarques',
    changePassword: 'Changer Le Mot De Passe',
    profilePic: 'Photo Public',
    updateProfilePic: 'Change Ma Photo',
    viewLettersByThisPen: 'Voir Les Lettres De Cette Pen',
    requestPending: 'Demand en Attente',
    activeCollaborator: 'Collaberateur Actuel',
    pendingYourApproval: 'En Attent De Votre Approbation',
    accept: 'Accepte'
};

export default frenchStrings;