const englishStrings = {
    welcome_to_appendre:'Welcome to Appendre',
    about_appendre:'The world\'s foremost French Learning Pen Pal Sight',
    login: 'Login',
    logout: 'Logout',
    username: 'Username',
    password: 'Password',
    signup: 'Signup',
    continue_as_guest: 'Continue as guest',
    profile: 'Profile',
    trending_letters: 'Trending Letters',
    trending_replies: 'Trending Replies',
    french: 'Français',
    english: 'English',
    collaborators: 'Collaborators',
    myLetters: 'My Letters',
    writeALetter: 'Write a Letter',
    whatDoYouWantToSay: 'What do you want to say',
    title: 'Title',
    add: 'Add',
    letter: 'Letter',
    tags: 'Tags',
    by: 'By',
    likes: 'Likes',
    dislikes: 'Dislikes',
    postedOn: 'Posted On',
    post: 'Post',
    replies: 'Replies',
    reply: 'Reply',
    search: 'Search',
    text: 'Text',
    tag: 'Tag',
    author: 'Author',
    date: 'Date',
    role: 'Role',
    user: 'User',
    searchAppendre: 'Search Appendre',
    resultsFor: 'Results for',
    with: 'with',
    matching: 'matching',
    viewProfile: 'View Profile',
    following: 'Following',
    pensIFollow: 'Pens I Follow',
    follow: 'Follow',
    collaborate: 'Collaborate',
    myCollaborators: 'My Collaborators',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    submitFeedback: 'Submit Feedback',
    feedback: 'Feedback',
    changePassword: 'Change Password',
    profilePic: 'Profile Pic',
    updateProfilePic: 'Update Profile Pic',
    viewLettersByThisPen: 'View Letters By This Pen',
    requestPending: 'Request Pending',
    activeCollaborator: 'Active Collaborator',
    pendingYourApproval: 'Pending Your Approval',
    accept: 'Accept'
};

export default englishStrings;